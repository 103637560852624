export default [
  {
    component: 'CNavItem',
    name: 'Dashboard',
    to: '/dashboard',
    icon: 'cil-speedometer',
    // badge: {
    //   color: 'primary',
    //   text: 'NEW',
    // },
  },
  {
    component: 'CNavTitle',
    name: 'Components',
  },
  {
    component: 'CNavItem',
    name: 'Users',
    to: '/users',
    icon: 'cil-people',
  },
  {
    component: 'CNavItem',
    name: 'Rides',
    to: '/rides',
    icon: 'cil-car-alt',
  },
  {
    component: 'CNavItem',
    name: 'Plans',
    to: '/plans',
    icon: 'cil-Calendar',
  },

  {
    component: 'CNavItem',
    name: 'Withdrawals',
    to: '/withdrawals',
    icon: 'cil-Calendar',
  },
  {
    component: 'CNavItem',
    name: 'Transactions',
    to: '/transactions',
    icon: 'cil-wallet',
  },
  {
    component: 'CNavItem',
    name: 'Percentages',
    to: '/percentages',
    icon: 'cil-flag-alt',
  },

  // {
  //   component: 'CNavGroup',
  //   name: 'Pages',
  //   to: '/pages',
  //   icon: 'cil-star',
  //   items: [
  //     {
  //       component: 'CNavItem',
  //       name: 'Login',
  //       to: '/pages/login',
  //     },
  //     // {
  //     //   component: 'CNavItem',
  //     //   name: 'Register',
  //     //   to: '/pages/register',
  //     // },
  //     {
  //       component: 'CNavItem',
  //       name: 'Error 404',
  //       to: '/pages/404',
  //     },
  //     {
  //       component: 'CNavItem',
  //       name: 'Error 500',
  //       to: '/pages/500',
  //     },
  //   ],
  // },

  // {
  //   component: 'CNavItem',
  //   name: 'Download CoreUI',
  //   href: 'http://coreui.io/vue/',
  //   icon: { name: 'cil-cloud-download', class: 'text-white' },
  //   _class: 'bg-success text-white',
  //   target: '_blank'
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'Try CoreUI PRO',
  //   href: 'http://coreui.io/pro/vue/',
  //   icon: { name: 'cil-layers', class: 'text-white' },
  //   _class: 'bg-danger text-white',
  //   target: '_blank'
  // }
]
