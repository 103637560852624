import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import CoreuiVue from '@coreui/vue'
import Corecharts from '@coreui/vue-chartjs'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'

const app = createApp(App)
app.use(store)
app.use(router)
app.use(CoreuiVue)
app.use(Corecharts)
app.provide('icons', icons)
app.component('CIcon', CIcon)


//Dev URL
// app.config.globalProperties.$my_domain_images = 'https://www.tererides.ee/ride_dev_assets/'
// app.config.globalProperties.$my_domain = 'https://www.tererides.com/tere-mvc-dev/api/admin'

//Prod URL
app.config.globalProperties.$my_domain_images = 'https://www.tererides.ee/ride_assets/'
app.config.globalProperties.$my_domain = 'https://www.tererides.com/tere-mvc-pro/api/admin'

//local
//app.config.globalProperties.$my_domain = 'http://localhost:9025/api/admin'


app.mount('#app')
