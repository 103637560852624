<template>
  <CFooter>
    <div>
      <a href="https://tererides.com" target="_blank">Tere</a>
      <span class="ms-1"
        >&copy; {{ new Date().getFullYear() }}| Let's Travel
        Together.</span
      >
    </div>
    <div class="ms-auto">
      <span class="me-1" >Powered by</span>
      <a href="https://codematics.co" target="_blank">Codematics Inc.</a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>
