<template>
  <router-view />
</template>


<style lang="scss">
.a{
  color: black !important
}

body{
font-family: cursive !important;
}
// Import Main styles for this application
@import 'styles/style';
</style>
